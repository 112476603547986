import { Heading, Stack } from '@chakra-ui/react'
import type { Product } from '@twlvxtwlv/tokenforge'
import type { LoginInput } from '@twlvxtwlv/types'
import { useTranslation } from 'next-i18next'
import { LoginForm } from '../../../shared/forms/login-form'
import { ProductPreviewBar } from '../../../shared/product-preview-bar'

interface LoginProps {
  error?: string
  onGotoSignUp: () => void
  onPasswordReset: () => void
  onSubmit: (loginInput: LoginInput) => void
  origin?: string | null | undefined
  product: Product | undefined
}

export function Login({
  error,
  onGotoSignUp,
  onPasswordReset,
  onSubmit,
  origin,
  product,
}: LoginProps) {
  const { t } = useTranslation(['user-management', 'common', 'claim'])
  return (
    <Stack w="full" justify="center">
      <Stack gap={2}>
        <Heading w="full" as="h3">
          {product
            ? t(origin === 'claim' ? 'claim:login-for-claim-title' : 'login-for-product-title')
            : t('login-title')}
        </Heading>

        {product ? <ProductPreviewBar product={product} showPrice={origin !== 'claim'} /> : null}
        <Stack display="flex" spacing={0} gap={8} w="full" justifyContent="center">
          <LoginForm
            onSubmit={onSubmit}
            onGotoSignUp={onGotoSignUp}
            onPasswordReset={onPasswordReset}
            error={error}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

import { useDisclosure } from '@chakra-ui/react'
import { dehydrate, QueryClient } from '@tanstack/react-query'
import {
  handleAliasOnLogin,
  serverSideSetUserProperties,
  serverSideTrackEvent,
} from '@twlvxtwlv/analytics/browser'
import { _trackEventOnServerWithLogging } from '@twlvxtwlv/analytics/server'
import { isDomainInternal } from '@twlvxtwlv/analytics/utils'
import { getBackgroundImageUrl } from '@twlvxtwlv/cms'
import {
  getUseClaimOptions,
  getUseProductOptions,
  useClaim,
  useFathomRegistrationEvent,
  useProduct,
} from '@twlvxtwlv/hooks'
import { extractRequestUrl } from '@twlvxtwlv/tokenforge'
import type { LoginInput } from '@twlvxtwlv/types'
import { Login } from '@twlvxtwlv/ui/src/components/pages/marketplace/login'
import { VerificationSuccessModal } from '@twlvxtwlv/ui/src/components/shared/modals/verification-success-modal'
import { TaskContainer } from '@twlvxtwlv/ui/src/components/shared/task-container'
import { _initDistinctId, initDistinctId } from '@twlvxtwlv/utils/src/cookie'
import {
  extractFirstQueryParamValue,
  extractRedirectUrl,
  extractUuidFromUri,
} from '@twlvxtwlv/utils/src/routing'
import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next'
import { getSession, signIn } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { getUseCampaignOptions, useCampaign } from '@twlvxtwlv/hooks/src/cms/use-campaign'

interface LoginPageProps {
  campaignId: string | null
  claimId: string | null
  productId: string | null
  locale: string
  origin: string | null
  rawParams: string | null
  redirectTo: string | null
}

export default function LoginPage({
  campaignId,
  claimId,
  productId,
  locale,
  origin,
  rawParams,
  redirectTo,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const router = useRouter()
  const { t } = useTranslation(['user-management', 'common', 'claim'])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [error, setError] = useState<string | undefined>(undefined)
  const verification = extractFirstQueryParamValue(router.query.verification)

  const { product } = useProduct({
    id: productId!,
    locale,
  })

  const { claimAttributes } = useClaim({
    locale,
    claimId,
  })

  const { campaignAttributes } = useCampaign({
    locale,
    id: campaignId,
    currentNftsPage: 1,
    currentNftsPageSize: 1,
  })

  useEffect(() => {
    if (verification === 'success') {
      onOpen()
    }
  }, [verification])

  useFathomRegistrationEvent('W5KNXRWA', verification)

  const distinctId = initDistinctId()

  useEffect(() => {
    const verificationValue = extractFirstQueryParamValue(router.query.verification)
    if (verificationValue === 'success' && distinctId) {
      serverSideTrackEvent(
        {
          userId: distinctId,
        },
        { eventName: 'sign-up-marketplace-completed' }
      )
    }
  }, [])

  async function handleOnSubmit({ email, password }: LoginInput) {
    // clear error before request
    setError(undefined)

    const redirectUrl = extractRedirectUrl({
      campaignId,
      claimId,
      productId,
      redirectTo,
    })

    const response = await signIn('token-forge', {
      email,
      password,
      redirect: false,
    })

    if (!response || response.status === 401) {
      setError(t('login-unauthorized') ?? undefined)
    } else if (response.status === 200) {
      serverSideTrackEvent(
        { userId: distinctId },
        {
          eventName: 'log-in-completed',
          redirectUrl,
        }
      )

      try {
        const session = await getSession()
        const tfUserId = extractUuidFromUri(session?.user?.customerIri)
        if (tfUserId) {
          await handleAliasOnLogin(tfUserId)
          await serverSideSetUserProperties(
            { userId: tfUserId },
            {
              propertiesName: 'is-user-internal-properties',
              isInternalUser: isDomainInternal(email),
            }
          )
        }
      } catch (e) {
        console.error('Could not identify mixpanel user when logging in')
      }
      await router.push(redirectUrl)
    } else {
      setError(`${response.status} ${response.error}`)
    }
  }

  function handleOnGotoSignUp() {
    const redirectUrl = rawParams ? `/sign-up?${decodeURIComponent(rawParams)}` : `/sign-up`
    router.push(redirectUrl)
  }

  function handleOnPasswordReset() {
    const redirectUrl = rawParams
      ? `/forgotten-password?${decodeURIComponent(rawParams)}`
      : `/forgotten-password`
    router.push(redirectUrl)
  }

  return (
    <>
      <Head>
        <title>{t('common:meta-title-login')}</title>
        <meta name="description" content={t('common:meta-description-login') ?? undefined} />
      </Head>
      <TaskContainer
        bgImage={getBackgroundImageUrl(
          claimAttributes?.image?.data?.attributes?.url ??
            campaignAttributes?.Background?.data?.attributes?.url
        )}
        bgRepeat={false}
      >
        <Login
          error={error}
          onGotoSignUp={handleOnGotoSignUp}
          onPasswordReset={handleOnPasswordReset}
          onSubmit={handleOnSubmit}
          origin={origin}
          product={product}
        />

        <VerificationSuccessModal
          headlineText={t('sign-up-verification-successful-modal-headline')}
          bodyText={t('sign-up-verification-successful-modal-body')}
          buttonTitle={t('sign-up-verification-successful-modal-button-title')}
          onButtonClick={onClose}
          onClose={onClose}
          isOpen={isOpen}
        />
      </TaskContainer>
    </>
  )
}

export const getServerSideProps: GetServerSideProps<LoginPageProps> = async ({
  req,
  res,
  resolvedUrl,
  locale = 'de',
}: GetServerSidePropsContext) => {
  const distinctId = _initDistinctId({ req, res })

  const userAgent = req.headers['user-agent'] as string

  _trackEventOnServerWithLogging(
    { userId: distinctId },
    { eventName: 'login-page-visited' },
    userAgent
  )

  const queryClient = new QueryClient()

  const { origin, productId, campaignId, claimId, rawParams, redirectTo } =
    extractRequestUrl(resolvedUrl)

  const requests = []
  if (productId) {
    const productRequest = queryClient.prefetchQuery(
      getUseProductOptions({
        id: productId,
        locale,
      })
    )

    requests.push(productRequest)
  }

  if (claimId) {
    const claimRequest = queryClient.prefetchQuery(getUseClaimOptions({ locale, claimId }))

    requests.push(claimRequest)
  }

  if (campaignId) {
    const campaignRequest = queryClient.prefetchQuery(
      getUseCampaignOptions({
        locale,
        id: campaignId,
        currentNftsPage: 1,
        currentNftsPageSize: 1,
      })
    )

    requests.push(campaignRequest)
  }

  await Promise.allSettled(requests)

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      campaignId,
      locale,
      origin,
      claimId,
      productId,
      rawParams,
      redirectTo,
      ...(await serverSideTranslations(locale, ['checkout', 'claim', 'common', 'user-management'])),
    },
  }
}

import { AspectRatio, Divider, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import type { Product, ProductVariant } from '@twlvxtwlv/tokenforge'
import { getPriceInEurocentsForProduct, toPublicMediaFileUri } from '@twlvxtwlv/tokenforge'
import { toLocalizedEuro } from '@twlvxtwlv/utils/localization'
import { useEffect, useState } from 'react'
import { ProductVideo } from '../video'

interface ProductPreviewBarProps {
  auctionId?: number | undefined | null
  product: Product
  showPrice?: boolean | undefined
}

export function ProductPreviewBar({
  product,
  auctionId,
  showPrice = true,
}: ProductPreviewBarProps) {
  const { t } = useTranslation('checkout')
  const [isLoading, setIsLoading] = useState(!!auctionId)

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false)
      }, 4000)
    }
  }, [])

  const { name, creatorProfileName, mediaFiles } = product
  const images = mediaFiles?.collection?.map((mediaFile) => {
    return {
      alt: mediaFile?.fileName,
      src: toPublicMediaFileUri(mediaFile),
    } as HTMLImageElement
  })
  let priceInEuroCents = -1
  const variant: ProductVariant | undefined | null = product.defaultVariant
  if (variant) {
    priceInEuroCents = getPriceInEurocentsForProduct(product)
  }
  return (
    <Stack w="full" gap={2}>
      <Divider borderColor="txtBlack" />
      <Flex direction={['column', 'row']} w="full" align="center" justify="center" gap={4}>
        <Flex justify="flex-start">
          {images && images.length > 0 ? (
            <AspectRatio w={[24, 32]} ratio={1}>
              {images[0].src.endsWith('.mp4') ? (
                <ProductVideo url={images[0].src} />
              ) : (
                <Image
                  src={images[0].src}
                  alt={t('nft-avatar-image-alt')}
                  fill
                  priority
                  sizes="128px"
                />
              )}
            </AspectRatio>
          ) : null}
        </Flex>
        <Flex direction={['column', 'column', 'row']} w="full" h="full" gap={2}>
          <Stack justify={['flex-end', 'flex-end', 'center']} w="full" spacing={0} gap={0.5}>
            <Text textAlign="left" fontSize="md" fontWeight="600" align="center" overflowY="hidden">
              {name}
            </Text>
            <Heading fontSize="xs" color="txtDarkGray" fontWeight="400">
              {creatorProfileName}
            </Heading>
          </Stack>
          {showPrice ? (
            <Stack
              align={['flex-start', 'flex-start', 'flex-end']}
              justify={['flex-end', 'flex-end', 'center']}
              w="full"
              spacing={0}
              gap={0.5}
            >
              {!isLoading ? (
                <Text fontSize={['md', 'md', '2xl']} fontWeight="600">
                  {toLocalizedEuro(priceInEuroCents)}
                </Text>
              ) : (
                <Flex
                  justify={['flex-start', 'flex-start', 'flex-end']}
                  w={['45px', '45px', '70px']}
                  h={['45px', '45px', '70px']}
                >
                  <AspectRatio w="full" ratio={1}>
                    <Image
                      src={'/images/twelve-logo-txt-circle-black-spinning.gif'}
                      alt="txt-logo-black-gif"
                      fill
                    />
                  </AspectRatio>
                </Flex>
              )}
              <Heading fontSize="xs" color="txtDarkGray" fontWeight="400" textAlign="right">
                {t('total-price')}
              </Heading>
            </Stack>
          ) : null}
        </Flex>
      </Flex>
      <Divider borderColor="txtBlack" />
    </Stack>
  )
}

import { Button, Flex, Heading, Text } from '@chakra-ui/react'
import { DefaultModal } from '../default-modal'

export interface VerificationSuccessModalProps {
  bodyText: string
  buttonTitle: string
  headlineText: string
  isOpen: boolean
  onButtonClick: () => void
  onClose: () => void
}

export const VerificationSuccessModal = ({
  isOpen,
  onClose,
  headlineText,
  bodyText,
  buttonTitle,
  onButtonClick,
}: VerificationSuccessModalProps) => {
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={false}
      size={['sm', 'md']}
      body={
        <Flex direction="column" gap={8} mb={4} textAlign="center">
          <Heading as="h2" size="md">
            {headlineText}
          </Heading>
          <Text align="center">{bodyText}</Text>
        </Flex>
      }
      footer={
        <Button variant="txtWhite" bg="txtSignalLime" size="md" onClick={onButtonClick}>
          {buttonTitle}
        </Button>
      }
    />
  )
}
